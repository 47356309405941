:root {
  --primary-color: #785dfb;
  --primary-color-dark: #3f2c91;
  --secondary-color: #cb4d94;
  --secondary-color-dark: #a02e6f;
  --accent-color: #81f0e8;
  --cta-color: var(--accent-color);

  --background-color: #15141f;
  --surface-color: #201e2f;

  --text-color: #fbfbff;
  // --text-color: #302c42;

  --border-color: #35298f;
  --border: 1px solid var(--border-color);

  --gradient: #785dfb;
  --gradient-background: var(--gradient) 50% 50% / cover no-repeat scroll;

  --top-menu-height: 70px;
  --lecture-footer-height: 72px;
  --max-content-height: calc(100vh - var(--top-menu-height) - var(--lecture-footer-height));
  --max-content-height-without-footer: calc(100vh - var(--top-menu-height));

  --sidebar-width: 500px;
  --code-background-color: rgb(40, 42, 54);

  --yellow: #ffd300;
  --red: rgb(220, 53, 69);
  --green: #56bd5b;
  --gray: #636d6d;


  --dashboard-right-sidebar-width: 500px;
}

html body {
  margin: 0;

  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5;

  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
}

* {
  box-sizing: border-box;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--surface-color) inset !important;
  -webkit-text-fill-color: #ddd !important;
  background-color: transparent !important;
}

::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px var(--border-color);
  border-radius: 0.5em;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.5em;
  background-color: var(--surface-color);
  outline: 1px solid var(--primary-color);
}

h1 {
  font-weight: bold;
  font-size: 3rem;
  line-height: 1.25;
}

h2 {
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 1.25;
}

h3 {
  font-weight: bold;
  font-size: 2rem;
  line-height: 1.25;
}

h4 {
  font-size: 1.75rem;
  line-height: 1.25;
}

textarea {
  background: transparent;
  font-family: Roboto, sans-serif;
}

p {
  margin: 0;
}

.tox-menu {
  background-color: var(--background-color) !important;

  .tox-collection__item {
    color: var(--text-color) !important;

    &.tox-collection__item--enabled {
      background-color: var(--secondary-color) !important;
    }

    &.tox-collection__item--active {
      background-color: var(--primary-color) !important;
    }
  }
}

textarea {
  resize: none;
}

.tox .tox-tbtn--disabled {
  background-color: transparent !important;
}

.tox .tox-dialog {
  background-color: var(--surface-color) !important;
  color: var(--text-color) !important;

  &__body,
  &__footer,
  &__header {
    background-color: var(--surface-color) !important;
    color: var(--text-color) !important;

    .tox-form__group {
      label.tox-label {
        color: var(--text-color) !important;
      }

      input {
        margin: .5rem 0;
        background-color: var(--background-color) !important;
        border: var(--border) !important;
        width: 100%;
        padding: 1rem;
        border-radius: 4px;
        font-size: 16px;
        color: #ebebeb;
        transition: border-color 0.2s, box-shadow 0.2s;
        line-height: unset;

        &:focus {
          outline: none !important;
          border-color: var(--primary-color) !important;
          box-shadow: 0 0 0 3px rgba(#785dfb, 0.2) !important;
        }
      }
    }

    svg {
      fill: var(--text-color) !important;
    }
  }

  &-wrap__backdrop {
    background-color: rgba(0, 0, 0, 0.75) !important;
  }
}

.tox .tox-pop.tox-pop--bottom {

  &:before,
  &:after {
    border-color: var(--background-color) transparent transparent transparent !important;
  }

  .tox-pop__dialog {
    border-color: var(--background-color) !important;
    background-color: var(--background-color) !important;
    color: var(--text-color) !important;

    .tox-tbtn {
      background-color: transparent !important;

      svg {
        fill: var(--text-color) !important;
      }
    }
  }
}

.tox-toolbar__overflow {
  background-color: var(--surface-color) !important;
  border: var(--border) !important;

  .tox-tbtn,
  .tox-split-button {
    outline: none !important;
    background-color: transparent !important;
    color: var(--text-color) !important;

    svg {
      fill: var(--text-color) !important;
    }
  }
}